@mixin dash {
  background: rgb(0, 65, 255);
  background: linear-gradient(
    45deg,
    rgb(0, 65, 255) 0%,
    rgb(255, 222, 0) 50%,
    rgb(0, 175, 5) 100%
  );
  height: 2px;
  content: "";
  display: block;
  width: 100%;
}

.layout {
  &:after {
    @include dash();
  }

  &__header,
  &__footer {
    padding: 2rem 4rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  &__footer {
    margin-top: 8rem;
  }

  &__presentation {
    padding: 8rem 0;
  }

  &__paragraph {
    padding: 2rem 0;
  }

  &__body {
    width: calc(100% - 4rem);
    padding: 2rem;
    margin: 0 auto;
  }

  &__projects {
    background-color: #efefef;
  }

  &__breadcrumb a {
    margin-right: 2rem;
    font-size: 1.5rem;
  }

  &__link {
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 3rem;
    text-transform: uppercase;
  }

  &--d-flex {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .layout {
    &__header {
      position: relative;
      flex-direction: column;
      padding-top: 8rem;
    }

    &__header,
    &__footer {
      padding: 2rem;
    }

    &__presentation {
      padding: 2rem 0;
    }

    &--d-flex {
      flex-direction: column;
    }
  }
}

@mixin darkTheme {
  .layout {
    &__header {
      background-color: #333;
    }

    &__projects {
      background-color: #292929;
    }
  }
}

.theme--dark {
  @include darkTheme();
}

@media (prefers-color-scheme: dark) {
  .theme--auto {
    @include darkTheme();
  }
}
