.logo {
  font-family: "Quicksand", sans-serif !important;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    margin-right: 0.5rem;

    img {
      border-radius: 50%;
      width: 46px;
      height: 46px;
    }
  }

  &__first-name {
    font-weight: bold;
  }
}

@media (max-width: 992px) {
  .logo {
    margin-bottom: 4rem;
    flex-direction: column;
    font-size: 32px;

    &__content {
      text-align: center;
    }

    &__image {
      margin: 0;

      img {
        width: 184px;
        height: 184px;
      }
    }
  }
}
