.social-network {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 22px;

  li {
    margin-left: 2rem;
  }
}

@media (max-width: 992px) {
  .social-network {
    display: block;

    li {
      display: inline-block;
      margin: 1rem;
    }
  }
}
