.badge {
  padding: 0.5rem 1rem;
  border-radius: 30px;
  background-color: #efefef;
  margin-right: 0.5em;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0.5rem;
  line-height: 1rem;

  &.clickable {
    cursor: pointer;
  }
}

@mixin darkTheme {
  .badge {
    background-color: #292929;
  }
}

.theme--dark {
  @include darkTheme();
}

@media (prefers-color-scheme: dark) {
  .theme--auto {
    @include darkTheme();
  }
}
