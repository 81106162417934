.card {
  border-radius: 8px;
  margin-bottom: 1rem;
  word-break: break-word;
  line-height: 1.75rem;
  background-color: #fff;
  border: 1px solid #efefef;

  &__content {
    padding: 1rem;
  }

  &__image {
    overflow: hidden;
    border-radius: 8px 8px 0 0;

    img {
      width: 100%;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-family: "Quicksand", sans-serif;
    letter-spacing: -1px;
  }

  &__title:hover,
  &__description:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &__date {
    font-size: 0.75rem;
  }

  &__featured {
    display: inline-block;
    margin-right: 0.25em;
  }
}

@mixin darkTheme {
  .card {
    background-color: #242424;
    border: 1px solid #222;
  }
}

.theme--dark {
  @include darkTheme();
}

@media (prefers-color-scheme: dark) {
  .theme--auto {
    @include darkTheme();
  }
}
