.reactions {
  display: flex;
  align-items: center;
  user-select: none;

  &--small &__items-icon {
    font-size: 14px;
    margin-left: 0.075rem;
  }

  &--small &__items {
    font-size: 12px;
    margin-right: 0.75rem;
  }

  &__items {
    margin-right: 1.25rem;
    cursor: pointer;
    position: relative;

    &:hover .reactions__items-icon {
      transform: scale(1.25);
    }

    &:active .reactions__items-icon {
      transform: scale(1);
    }
  }

  &__items-icon {
    transition: transform 0.2s ease-in-out;
    font-size: 1.25rem;
    margin-left: 0.25rem;
    display: inline-block;
  }

  &__items-total {
    font-weight: 400;
  }

  &__animation {
    position: absolute;
    top: 0;
    left: 0;
    animation-name: animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    white-space: nowrap;
  }
}

@keyframes animation {
  0% {
    opacity: 0;
    transform: translate(0, -50%);
  }
  50% {
    opacity: 1;
    transform: translate(0, -100%);
  }
  100% {
    opacity: 0;
    transform: translate(0, -150%);
  }
}

@media (max-width: 992px) {
  .reactions {
    max-width: 100%;
    overflow-x: auto;
    height: 100px;
  }
}
