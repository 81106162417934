@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");

html,
body {
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
  background-color: #fff;
  color: #333;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin darkTheme {
  background-color: #333;
  color: #fff;

  a {
    color: #fff;
  }
}

body {
  .theme--dark:not(.page--login) {
    @include darkTheme();
  }
}

@media (prefers-color-scheme: dark) {
  body {
    .theme--auto:not(.page--login) {
      @include darkTheme();
    }
  }
}
